/* src/app/support/restricted-zip-codes/restricted-zip-codes.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.restricted-zip-codes .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  color: #242424;
  font-size: 12px;
}
.restricted-zip-codes .wrapper .instruction {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.8em 2em;
}
.restricted-zip-codes .wrapper .sections {
  display: flex;
  flex-direction: row;
}
.restricted-zip-codes .wrapper .sections .section {
  display: flex;
  flex-direction: column;
  padding: 0.8em 2em;
}
.restricted-zip-codes .wrapper p {
  margin: 0 0 3px;
}
.restricted-zip-codes .wrapper .terms {
  padding-top: 3em;
}
/*# sourceMappingURL=restricted-zip-codes.component.css.map */
