/* src/app/address-book/address-book/manage-address/manage-address.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.manage-address-dialog {
  font-size: 1rem;
}
.manage-address-dialog .ups-form .form-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1.5em 0 0 0;
}
@media screen and (min-width: 48rem) {
  .manage-address-dialog .ups-form .form-body {
    height: 52vh;
    max-height: 32.125rem;
  }
}
.manage-address-dialog .ups-form .form-body .mat-mdc-form-field,
.manage-address-dialog .ups-form .form-body .checkboxes {
  padding: 0 1em;
}
@media screen and (min-width: 48rem) {
  .manage-address-dialog .ups-form .form-body .mat-mdc-form-field,
  .manage-address-dialog .ups-form .form-body .checkboxes {
    width: 50%;
  }
}
.manage-address-dialog .ups-form .form-body .checkboxes .mat-mdc-checkbox {
  margin-right: 2em;
}
.manage-address-dialog .ups-form .form-footer .form-actions {
  padding: 1.5em 1em;
}
/*# sourceMappingURL=manage-address.component.css.map */
