/* src/app/support/support-documents/support-documents.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.support-documents,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.support-documents {
  background-color: white;
}
.support-documents .form-body {
  max-width: 75rem;
  padding: 0rem 2rem;
  margin-top: 20px;
}
.support-documents .form-body .subtitle {
  margin-bottom: 40px;
  font-weight: bold;
}
.support-documents .form-body .documents {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
@media screen and (min-width: 48rem) {
  .support-documents .form-body .documents {
    width: 50%;
    padding-left: 30px;
  }
}
.support-documents .form-body .documents .document {
  display: block;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 28px;
}
@media screen and (min-width: 48rem) {
  .support-documents .form-body .documents .document {
    display: flex;
  }
}
@media screen and (max-width: 48rem) {
  .support-documents .form-body .documents .document {
    align-items: center;
  }
}
.support-documents .form-body .documents .document .file-label {
  width: 100%;
}
.support-documents .form-body .documents .document .upsc-s3-file-downloader {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .support-documents .form-body .documents .document .upsc-s3-file-downloader {
    margin-left: 20px;
  }
}
/*# sourceMappingURL=support-documents.component.css.map */
