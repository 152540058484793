/* src/app/support/supply-order/order-supplies-history/order-supplies-history.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.order-supplies-history .ups-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  padding: 0em 0.5em;
}
@media screen and (min-width: 48rem) {
  .order-supplies-history .ups-page-header {
    font-size: 1rem;
    padding: 1.125em 1.5em;
  }
}
.order-supplies-history .ups-page-header .page-title {
  font-size: 1.1em;
}
@media screen and (min-width: 48rem) {
  .order-supplies-history .ups-page-header .page-title {
    font-size: 1.25em;
  }
}
.order-supplies-history .action {
  font-size: 0.9em;
  padding: 0px 2px;
  height: 35px;
}
@media screen and (min-width: 48rem) {
  .order-supplies-history .action {
    font-size: 0.8em;
    padding: 0 26px;
    height: 40px;
  }
}
.order-supplies-history .action .material-icons {
  font-size: 15px;
}
@media screen and (min-width: 48rem) {
  .order-supplies-history .action .material-icons {
    font-size: 18px;
  }
}
.order-supplies-history .instruction {
  color: #595555;
  font-size: 0.9em;
  width: 70%;
  padding: 0em 0em 0em 1em;
}
@media screen and (min-width: 48rem) {
  .order-supplies-history .instruction {
    width: 100%;
    padding: 0em 2.5em 4em 2.8em;
  }
}
.order-supplies-history .dataTables_wrapper,
.order-supplies-history .ngx-pagination {
  margin: 1em 0 2em !important;
}
@media screen and (min-width: 48rem) {
  .order-supplies-history .dataTables_wrapper,
  .order-supplies-history .ngx-pagination {
    width: 70%;
  }
}
.order-supplies-history .dataTables_wrapper .mat-mdc-raised-button,
.order-supplies-history .ngx-pagination .mat-mdc-raised-button {
  font-weight: 600;
  font-size: 0.8em;
  padding: 0 8px;
  height: 35px;
}
@media screen and (min-width: 48rem) {
  .order-supplies-history .dataTables_wrapper .mat-mdc-raised-button,
  .order-supplies-history .ngx-pagination .mat-mdc-raised-button {
    font-size: 0.9em;
    padding: 0 15px;
    height: 35px;
  }
}
.order-supplies-history table.dataTable thead th,
.order-supplies-history table.dataTable thead td {
  padding: 8px 10px;
}
.order-supplies-history .trigger-column,
.order-supplies-history .trigger-cell {
  display: none;
}
.order-supplies-history .address {
  max-width: 130px;
  display: table-cell;
}
@media screen and (min-width: 48rem) {
  .order-supplies-history .address {
    max-width: 100%;
  }
}
.order-supplies-history .address div {
  max-width: 130px;
}
@media screen and (min-width: 48rem) {
  .order-supplies-history .address div {
    display: inline;
  }
}
.order-supplies-history .order-number {
  width: 100%;
}
.order-supplies-history .details {
  padding-left: 0;
}
/*# sourceMappingURL=order-supplies-history.component.css.map */
