/* src/app/address-book/address-book/address-book.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.address-books {
  font-size: 1rem;
}
.address-books .page-actions {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0 2rem 1rem;
}
@media screen and (min-width: 48rem) {
  .address-books .page-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}
.address-books .page-actions .instructions {
  color: #009cbd;
  margin: 1em 0;
}
@media screen and (min-width: 48rem) {
  .address-books .page-actions .instructions {
    margin: 0;
  }
}
.address-books .page-actions .mdc-button {
  font-weight: 400;
}
.address-books .page-actions .button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0;
}
@media screen and (min-width: 48rem) {
  .address-books .page-actions .button-group {
    padding-left: 0.5rem;
    margin-left: auto;
  }
}
.address-books .page-actions .button-group ::ng-deep .mdc-button {
  font-size: 0.8em;
  font-weight: 400;
  padding: 0 1rem;
  margin: 0 0.25rem;
  line-height: 1.2;
}
.address-books .page-actions .button-group ::ng-deep .mdc-button:first-child {
  margin-left: 0;
}
.address-books .page-actions .button-group ::ng-deep .mdc-button:last-child {
  margin-right: 0;
}
@media screen and (min-width: 48rem) {
  .address-books .page-actions .button-group ::ng-deep .mdc-button {
    font-size: 0.9em;
    padding: 0 2rem;
  }
}
.address-books .show-more {
  margin: 0 auto;
  padding: 1em 0;
}
.address-books .show-more .mat-mdc-raised-button {
  font-size: 0.8125rem;
  height: 2.1875rem;
}
.address-books .file-selector {
  position: relative;
  overflow: hidden;
}
.address-books .file-selector .file-input {
  position: absolute;
  left: -9999px;
  height: 0;
  visibility: hidden;
}
.address-books .file-selector .file-input-button {
  margin: 2px 0 3px 2px;
}
/*# sourceMappingURL=address-book.component.css.map */
