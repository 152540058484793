/* src/app/support/contact-us/contact-us.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content,
.contact-us {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content,
.contact-us {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content,
.contact-us {
  background-color: white;
}
.white-sheet-content .ups-page-header,
.contact-us .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header,
  .contact-us .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.contact-us {
  font-size: 1rem;
}
.contact-us .locations {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0 23px;
  margin-bottom: 1rem;
}
@media screen and (min-width: 48rem) {
  .contact-us .locations {
    padding: 0 32px;
  }
}
.contact-us .locations .location-title {
  font-weight: 600;
}
.contact-us .locations .contacts {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  font-size: 0.875rem;
}
@media screen and (min-width: 48rem) {
  .contact-us .locations .contacts {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 44rem;
  }
}
.contact-us .locations .contacts .contact {
  margin-bottom: 1rem;
}
@media screen and (min-width: 48rem) {
  .contact-us .locations .contacts .contact {
    width: 50%;
    max-width: 22rem;
  }
}
.contact-us .locations .contacts .contact .contact-title {
  font-weight: 600;
}
.contact-us .locations .contacts .contact .address-lines {
  margin-bottom: 0.5rem;
}
.contact-us .locations .contacts .contact .address-lines .address-line {
  line-height: 1.5;
}
.contact-us .locations .contacts .deprecated-key-values {
  font-size: 0.8125rem;
  margin: 0;
}
.contact-us .locations .contacts .deprecated-key-values .key-value {
  width: auto;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}
.contact-us .locations .contacts .deprecated-key-values .key-value .key {
  width: auto;
  min-width: 4rem;
  margin-right: 1rem;
}
.contact-us .locations .contacts .deprecated-key-values .key-value .value {
  width: auto;
  justify-content: flex-start;
  font-weight: 500;
}
/*# sourceMappingURL=contact-us.component.css.map */
